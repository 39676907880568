/**
 * @component Unit Map Picker
 */

.unit-map-picker {
  margin-top: -3rem;
  margin-bottom: -3rem;
  position: relative;
  width: 1024px;
}

.unit-map-picker__base-image {
  max-width: 100%;
}

.unit-map-picker__alert {
  position: fixed;
  bottom: calc(#{$dialog-footer-height});
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, 100px);
  z-index: 15;
  box-shadow: 0 4px 8px rgba(black, 0.3);
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) all;
  visibility: hidden;
}

.unit-map-picker__alert[active] {
  transform: translateX(-50%);
  visibility: visible;
}

.unit-map-picker__alert p {
  margin-bottom: 0;
}

/* Unit Map Picker Button
/* -------------------------------------------------------------------------- */
.unit-map-picker-btn {
  display: block;
  position: absolute;
  border: 0;
  outline: 1px solid $color-text;
  background-color: rgba($color-text, 0.4);
  -webkit-appearance: none;
  padding: 0;
  transition: 0.15s cubic-bezier(0.165, 0.84, 0.44, 1) all;

  &:focus {
    outline: 1px solid $color-text;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  }
}

.unit-map-picker-btn[status=available] {
  background-color: rgba($color-success, 0.4);
}

.unit-map-picker-btn[status=reserved] {
  background-color: rgba($color-warn, 0.4);
}

.unit-map-picker-btn[status=sold] {
  background-color: rgba($color-text, 0.4);
}

.unit-map-picker-btn[status=compare] {
  background-color: rgba($color-cyan, 0.4);
}

.unit-map-picker-btn[is-active] {
  // outline: 1px solid black;
  // box-shadow: 0 2px 2px 1px rgba(black, 0.5);
  // z-index: 5;
}
.unit-map-picker-btn[is-active]:after {
  background-color: rgba(black, 0.15);
}

.unit-map-picker-btn[is-selected] {
  outline: 3px solid black;
  box-shadow: 0 2px 3px 3px rgba(black, 0.5);
  z-index: 10;
}
.unit-map-picker-btn[is-selected][is-active]:after {
  background-color: transparent;
}


/* Unit Map Picker Tooltip
/* -------------------------------------------------------------------------- */
.unit-map-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 20;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) all;
  transform: translateY(-10px);
  
  &[show] {
    transition-property: opacity, transform;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.unit-map-tooltip > div {
  position: absolute;
  left: 50%;
  width: 240px;
  padding: $spacing-4;
  transform: translate(calc(-50% + 12px), calc(-100% - 18px));
  background-color: white;
  border: 1px solid $color-text;
  box-shadow: 0 2px 3px rgba(black, 0.5);
  text-align: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 50%;
    width: 0px;
    height: 0px;
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $color-text;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0px;
    height: 0px;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}

.unit-map-tooltip__header {
  margin-bottom: $spacing-2;
  padding-bottom: $spacing-2;
  border-bottom: 1px solid $color-border-l2;
}

.unit-map-tooltip__unit {
  display: block;
  color: $color-text-l3;
  font-size: 20px;
}

.unit-map-tooltip__unit em {
  font-weight: bold;
  color: $color-text;
  font-style: normal;
}

.unit-map-tooltip__status {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.unit-map-tooltip__status i {
  position: relative;
  display: inline-block;
  width: 6px;
  height: 6px;
  top: -2px;
  margin-right: $spacing-2;
  background-color: $color-success;
}

.unit-map-tooltip__body {
  font-size: 14px;
  color: $color-text-l3;
  margin-bottom: $spacing-2;
}

.unit-map-tooltip__footer {
  display: flex;
}

.unit-map-tooltip__footer > * {
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.unit-map-tooltip__footer .btn {
  display: block;
  width: 100%;
  height: 36px;
}