/**
 * @base Color Definitions
 */

/* Primary Color
/* -------------------------------------------------------------------------- */
$color-primary: rgb(52, 74, 76);
$color-primary-l1: lighten($color-primary, 10%);
$color-primary-l2: lighten($color-primary, 20%);
$color-primary-l3: lighten($color-primary, 30%);
$color-primary-l4: lighten($color-primary, 40%);
$color-primary-l5: lighten($color-primary, 50%);
$color-primary-d1: darken($color-primary, 5%);
$color-primary-d2: darken($color-primary, 10%);
$color-primary-d3: darken($color-primary, 15%);
$color-primary-d4: darken($color-primary, 20%);
$color-primary-d5: darken($color-primary, 25%);


/* Secondary Color
/* -------------------------------------------------------------------------- */
$color-secondary: rgb(213, 200, 174);
$color-secondary-l1: lighten($color-secondary, 10%);
$color-secondary-l2: lighten($color-secondary, 20%);
$color-secondary-l3: lighten($color-secondary, 30%);
$color-secondary-l4: lighten($color-secondary, 40%);
$color-secondary-l5: lighten($color-secondary, 50%);
$color-secondary-d1: darken($color-secondary, 5%);
$color-secondary-d2: darken($color-secondary, 10%);
$color-secondary-d3: darken($color-secondary, 15%);
$color-secondary-d4: darken($color-secondary, 20%);
$color-secondary-d5: darken($color-secondary, 25%);


/* Text Color
/* -------------------------------------------------------------------------- */
$color-text: rgb(38, 38, 38);
$color-text-l1: lighten($color-text, 10%);
$color-text-l2: lighten($color-text, 20%);
$color-text-l3: lighten($color-text, 30%);
$color-text-l4: lighten($color-text, 40%);
$color-text-l5: lighten($color-text, 50%);


/* Border Color
/* -------------------------------------------------------------------------- */
$color-border: rgb(155, 155, 155);
$color-border-l1: lighten($color-border, 10%);
$color-border-l2: lighten($color-border, 20%);
$color-border-l3: lighten($color-border, 30%);
$color-border-l4: lighten($color-border, 40%);
$color-border-l5: lighten($color-border, 50%);


/* Other Colors
/* -------------------------------------------------------------------------- */
$color-danger: #DC3645;
$color-success: #0DB863;
$color-active: #18A2B8;
$color-warn: #C8BB1C;
$color-red: #7A2F3D;
$color-cyan: #18A2B8;
$color-grey: #9B9B9B;