/**
 * @component Booking List Component
 */

.booking-list {
  @include m-list-unstyled;
  position: relative;
}

.booking-list * {
  user-select: none;
}

.booking-list-item {
  position: relative;
  border-bottom: 1px solid $color-border-l2;
  padding: $spacing-4 0;
  display: flex;
  align-items: center;
  &:focus { outline: none; }
}
.booking-list-item[open] {
  border-bottom-color: transparent;
  border-top: 1px solid $color-active;
  margin-top: -1px;
}

.booking-list-item:before {
  display: block;
  content: '';
  position: absolute;
  left: -$spacing-4;
  right: -$spacing-4;
  top: -1px;
  bottom: -1px;
}
.booking-list-item[open]:before {
  background-color: $color-active;
}


.booking-list-item > button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.booking-list-item__name {
  position: relative;
  display: block;
  font-weight: bold;
}
.booking-list-item[open] .booking-list-item__name {
  color: white;
}

.booking-list-item__details {
  position: relative;
  display: block;
  color: $color-text-l3;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: $typeface-system;
  z-index: 1;
}
.booking-list-item[open] .booking-list-item__details {
  color: rgba(white, 0.8);
}

.booking-list-item__chevron {
  display: block;
  position: relative;
  align-self: center;
  margin-left: auto;
  z-index: 1;
}
.booking-list-item__chevron .icon {
  fill: $color-border;
  transform: rotate(-90deg);
  width: 9px;
  height: 9px;
}
.booking-list-item[open] .booking-list-item__chevron .icon {
  fill: white;
}

.booking-list-item__actions {
  margin-left: auto;
}

/* Booking List Toggle
/* -------------------------------------------------------------------------- */
.booking-list-toggle {
  display: flex;
  margin-bottom: $spacing-4;
}

.booking-list-toggle .btn {
  flex-basis: 1;
  width: 100%;
  border-color: $color-border-l2;
}

.booking-list-toggle .btn:not(:last-of-type) {
  border-right: 0;
}

.booking-list-toggle .btn.active {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}
.booking-list-toggle .btn:not(:last-of-type).active {
  margin-right: -1px;
  border-right: 1px solid $color-primary !important;
  z-index: 2;
}


/* Empty State
/* -------------------------------------------------------------------------- */
.booking-list-empty {
  color: $color-text-l4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.booking-list-empty__title {
  color: $color-text-l1;
  font-size: 18px;
}

.booking-list-empty p {
  font-family: $typeface-system;
  font-size: 14px;
}