/**
 * @page Create Booking Screen
 */

.create-booking {
  padding-top: calc(#{$dialog-breadcrumb-height} + #{$spacing-6});
  padding-bottom: calc(#{$dialog-footer-height} + #{$spacing-6});
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}


/* Transitions
/* -------------------------------------------------------------------------- */
.create-booking-enter {
  animation: 0.3s create-booking-enter both cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-delay: 0.3s;
}

.create-booking-exit {
  z-index: 10;
  animation: 0.3s create-booking-exit both cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


@keyframes create-booking-enter {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes create-booking-exit {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}


/* Select Unit Screen
/* -------------------------------------------------------------------------- */
.select-unit-ui-toggler__btn {
  position: relative;
  background-color: transparent;
  transition: none;
  color: $color-text;
  outline: 1px solid $color-border;
}

.select-unit-ui-toggler__btn.active {
  background-color: $color-text !important;
  color: white;
  outline-color: $color-text;
  z-index: 10;
}


/* Enter Details Screen
/* -------------------------------------------------------------------------- */
.enter-details-actions {
  display: flex;
  padding-bottom: $spacing-3;
  margin-bottom: $spacing-4;
  border-bottom: 1px solid $color-border-l2;
}

.enter-details-actions .btn {
  height: 42px;
}

.enter-details-actions .btn .icon {
  position: relative;
  top: 2px;
}

/* Payment Plan Screen
/* -------------------------------------------------------------------------- */
.payment-plan-type {
  margin-bottom: $spacing-6;
}

.payment-plan-type__header {
  display: block;
  margin-top: 0;
  margin-left: $spacing-4;
  margin-bottom: $spacing-4;
  font-size: 22px;
  font-weight: bold;
  font-family: $typeface-primary;
}


/* Booking Fee Screen
/* -------------------------------------------------------------------------- */
.booking-fee-amount {
  background-color: lighten($color-grey, 20%);
  padding: $spacing-4;
  font-family: $typeface-primary;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: $spacing-5;
}

.booking-fee-amount__title {
  font-weight: bold;
}

.booking-fee-upload__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  -webkit-appearance: none;
}

.booking-fee-upload__container {
  display: flex;
  width: 100%;
  height: 240px;
  align-items: center;
  border: 1px dashed $color-border;
  background-color: darken(#e6e6e6, 5%);
  padding: $spacing-4;
  text-align: center;
}

.booking-fee-upload__container > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-fee-upload__container img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}


/* Confirmation Screen
/* -------------------------------------------------------------------------- */
.confirmation-screen-item {
  position: relative;
  margin-bottom: $spacing-5;
  color: $color-text-l3;
}

.confirmation-screen-item__title {
  font-size: 20px;
  display: block;
  margin-bottom: $spacing-3;
  color: $color-text;
}

.confirmation-screen-item__content {
  background-color: white;
  border: 1px solid $color-border;
  padding: $spacing-5;
}

.confirmation-unit-perspective {
  max-width: 100%;
  height: 280px;
  display: block;
  object-fit: cover;
}

.confirmation-unit {
  display: flex;
  align-items: center;
}

.confirmation-unit__icon {
  margin-right: $spacing-4;
}

.confirmation-unit__body span {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  color: $color-text-l3;
  margin-bottom: $spacing-1;
}

.confirmation-unit__body h4 {
  display: block;
  margin: 0;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $color-primary;
}

.confirmation-details {
  @include m-list-unstyled;
  margin-bottom: 0;
}

.confirmation-details > li {
  border-bottom: 1px solid $color-border-l2;
  padding-bottom: $spacing-3;
  margin-bottom: $spacing-3;
}

.confirmation-details > li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.confirmation-details__title {
  display: block;
  color: $color-text;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  margin-bottom: $spacing-1;
}

.confirmation-details__image {
  margin-top: $spacing-3;
  border: 1px dashed $color-border-l2;
  background-color: rgb(245, 245, 245);
  padding: $spacing-4;
}

.confirmation-details__image img {
  max-width: 100%;
}


/* Success Screen
/* -------------------------------------------------------------------------- */
.success-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.success-content__check {
  position: relative;
  width: 120px;
  height: 120px;
  background-color: $color-success;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $spacing-5;
}

.success-content__check .icon {
  position: absolute;
  width: 54px;
  height: 54px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: white;
}

.success-content__title {
  text-align: center;
  color: $color-text;
  font-size: 32px;
  margin-bottom: $spacing-5;
}