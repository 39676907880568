/**
 * @screen Manage Booking Screen
 */

.manage-bookings {
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.manage-bookings__col {
  padding: $spacing-4;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.manage-bookings__left {
  background-color: white;
  box-shadow: 2px 2px 4px rgba(black, 0.1);
  width: 340px;
  flex-grow: 0;
  flex-shrink: 0;
}

.manage-bookings__right {
  width: 100%;
  padding: $spacing-6;
}

.manage-bookings__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.convert-booking-screen {
  padding-bottom: calc(#{$dialog-footer-height} + #{$spacing-6});
}

/* Convert Booking Actions
/* -------------------------------------------------------------------------- */
.convert-booking-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: $spacing-5;
}

.convert-booking-actions .btn:not(:last-child) {
  margin-right: $spacing-2;
}

.convert-booking-actions .btn .icon {
  width: 12px;
  height: 12px;
  fill: rgba(white, 0.5);
}


/* Convert Booking Masthead
/* -------------------------------------------------------------------------- */
.convert-booking-masthead {
  background-color: white;
  padding: $spacing-4;
  margin-bottom: $spacing-5;
  box-shadow: 0 1px 3px rgba(black, 0.2);
}

.convert-booking-masthead__name {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.convert-booking-masthead__details {
  display: block;
  color: $color-text-l3;
  font-family: $typeface-system;
  font-size: 14px;
  margin-bottom: 2px;
}

.convert-booking-masthead__meta {
  display: block;
  font-size: 12px;
  color: $color-text-l4;
}


/* Convert Booking Accordion
/* -------------------------------------------------------------------------- */
.convert-booking-accordion {
  position: relative;
  margin-bottom: $spacing-5;
  background-color: rgb(215, 215, 215);
}

.convert-booking-accordion__header {
  padding: $spacing-5;
  -webkit-appearance: none;
  background-color: transparent;
  width: 100%;
  border: 0;
  display: block;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.convert-booking-accordion .badge .icon {
  fill: white;
  width: 12px;
  height: 12px;
  position: relative;
  top: 1px;
}

.convert-booking-accordion h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: $spacing-2;
}

.convert-booking-accordion__content {
  padding: $spacing-5;
  padding-top: 0;
}

.convert-booking-accordion__chevron .icon {
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) all;
}

.convert-booking-accordion[open] .convert-booking-accordion__chevron .icon {
  transform-origin: center center;
  transform: rotate(180deg);
}