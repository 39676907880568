/**
 * @component Details Card
 */

.details-card {
  @include m-list-unstyled;
  background-color: white;
  margin-bottom: $spacing-5;
  color: $color-text-l3;
  padding: $spacing-4;
  border: 1px solid $color-border;
  font-family: $typeface-system;
}

.details-card > li:not(:last-child) {
  border-bottom: 1px solid $color-border-l2;
  padding-bottom: $spacing-3;
  margin-bottom: $spacing-3;
}

.details-card__title {
  display: block;
  color: $color-text;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  margin-bottom: $spacing-1;
  font-family: $typeface-primary;
}

.details-card__image {
  margin-top: $spacing-3;
  border: 1px dashed $color-border-l2;
  background-color: rgb(245, 245, 245);
  padding: $spacing-4;
}

.details-card__image img {
  max-width: 100%;
}


