/* Auto-generated by Fonty, a friendly CLI tool for fonts. */

@font-face {
  font-family: 'Unique';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/unique/unique.ttf') format('truetype'),
       url('../fonts/unique/unique.woff') format('woff'),
       url('../fonts/unique/unique.woff2') format('woff2');
}
