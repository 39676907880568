/**
 * @base Layout Definitions
 */

body {
  background-color: rgb(230, 230, 230);
  touch-action: manipulation;
  -webkit-overflow-scrolling: touch;
}


/* Spacing Presets
/* -------------------------------------------------------------------------- */
$spacing-1: 0.25rem; // 4px (assuming 1.0rem = 16px)
$spacing-2: 0.5rem;  // 8px
$spacing-3: 0.75rem; // 12px
$spacing-4: 1rem;    // 16px
$spacing-5: 1.5rem;  // 24px
$spacing-6: 3rem;    // 48px


/* Container Overrides
/* -------------------------------------------------------------------------- */
.container.tight {
  max-width: 570px;
}