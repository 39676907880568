/**
 * @component Form
 */

/* Input Overrides
/* -------------------------------------------------------------------------- */
.form-control {
  border-radius: 0;
  border: 1px solid $color-border-l2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Form Error Labels
/* -------------------------------------------------------------------------- */
.form-error {
  font-size: 14px;
  color: $color-danger;
  display: block;
  margin-top: $spacing-1;
}