/**
 * @component Screen Loading Indicator
 */


.screen-loading-indicator {
  z-index: 15;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  background-color: rgba(white, 0.9);
  visibility: hidden;
  opacity: 0;
}

.screen-loading-indicator.is-fill-screen {
  position: fixed;
}

.screen-loading-indicator[show] {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
}

.screen-loading-indicator > div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.screen-loading-indicator .la-ball-clip-rotate {
  color: $color-text;
  width: 28px;
  height: 28px;
  margin-right: $spacing-3;
  & > div {
    width: 28px;
    height: 28px;
    border-width: 3px;
  }
}

.screen-loading-indicator__text {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;
  color: $color-text;
}