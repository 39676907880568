/**
 * @component Modal
 * @overrides Bootstrap v4.0
 */

.modal.fade .modal-dialog {
  transition: transform .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modal-backdrop {
  background-color: black;
  &.show { opacity: 0.7 }
}

.modal-content {
  border-radius: 0;
  border: 0;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.modal-header {
  background-color: $color-text;
  border-radius: 0;
}

.modal-header h5 {
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 18px;
}