/**
 * @component lavon-Breadcrumb
 */

$dialog-breadcrumb-height: 48px;

.lavon-breadcrumb {
  @include m-list-unstyled;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: $dialog-breadcrumb-height;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  align-items: stretch;
  margin-top: 0;
  margin-bottom: 0;
  background-color: white;
}

.lavon-breadcrumb__item {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: $spacing-4;
  padding-right: $spacing-4;
  &:last-child { border-right: 0; }
}

.lavon-breadcrumb__counter {
  width: 22px;
  height: 22px;
  color: white;
  background-color: rgb(210, 210, 210);
  border-radius: 50%;
  text-align: center;
  font-family: $typeface-primary;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 22px;
  margin-right: $spacing-2;
  position: relative;
}

.lavon-breadcrumb__title {
  font-family: $typeface-primary;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  color: $color-text-l4;
}

.lavon-breadcrumb__counter .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: white;
  width: 12px;
  height: 12px;
}

.lavon-breadcrumb__chevron {
  position: absolute;
  width: 22px;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  fill: $color-border-l1;
}

.lavon-breadcrumb__item:last-of-type {
  .lavon-breadcrumb__item {
    margin-right: -22px; // offset for lack of chevron
  }
  .lavon-breadcrumb__chevron {
    display: none;
  }
}

/* States
/* -------------------------------------------------------------------------- */

// Active
.lavon-breadcrumb__item[active] {

  /** Counter */
  .lavon-breadcrumb__counter {
    background-color: $color-primary;
  }

  /** Title */
  .lavon-breadcrumb__title {
    color: $color-text;
  }
}

// Valid
.lavon-breadcrumb__item[valid] {
  /** Counter */
  .lavon-breadcrumb__counter {
    background-color: rgba($color-success, 0.5);
  }

  /** Title */
  .lavon-breadcrumb__title {
    color: rgba($color-success, 0.5);
  }
}

// Active and Valid
.lavon-breadcrumb__item[valid][active] {
  /** Counter */
  .lavon-breadcrumb__counter {
    background-color: $color-success;
  }

  /** Title */
  .lavon-breadcrumb__title {
    color: $color-success;
  }
}
