/**
 * @component <ComparisonModal/>
 */

.comparison-modal-table {
  max-height: 600px;
  overflow: auto;
}

.comparison-modal .modal-header {
  border-bottom: 0;
}

.comparison-modal .modal-body {
  padding: 0;
}

.comparison-modal-table__row {
  display: flex;
  align-items: flex-start;
}

.comparison-modal-table__row + .comparison-modal-table__row {
  border-top: 1px solid $color-border-l1;
}

.comparison-modal-table__header {
  width: 180px;
  align-self: stretch;
  background-color: $color-secondary;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  padding: $spacing-4;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $typeface-secondary;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
}

.comparison-modal-table__content {
  width: 100%;
  padding: $spacing-4;
  text-align: center;
  color: $color-text-l1;
}

.comparison-modal-table__content + .comparison-modal-table__content {
  border-left: 1px solid $color-border-l2;
}

/* Comparison Email Button
/* -------------------------------------------------------------------------- */
.comparison-modal-email-btn {
  background-color: $color-active;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  box-shadow: 0 4px 8px rgba(black, 0.2);
  position: absolute;
  bottom: $spacing-4;
  right: $spacing-4;
  border: 0;
}

.comparison-modal-email-btn:focus {
  outline: none;
}

.comparison-modal-email-btn .icon {
  fill: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}