/**
 * @component Payment Plan Picker
 */

$payment-plan-picker-status-width: 5%;
$payment-plan-picker-terms-width: 20%;
$payment-plan-picker-period-width: 20%;
$payment-plan-picker-price-width: 20%;
$payment-plan-picker-actions-width: 35%;

/* Header
/* -------------------------------------------------------------------------- */
.payment-plan-picker-header {
  display: flex;
  margin-bottom: $spacing-2;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: $color-text-l5;
  letter-spacing: 1px;
}

.payment-plan-picker-header > div {
  padding-left: $spacing-4;
  padding-right: $spacing-4;
}

.payment-plan-picker-header__status {
  flex-basis: $payment-plan-picker-status-width;
}

.payment-plan-picker-header__terms {
  flex-basis: $payment-plan-picker-terms-width;
}

.payment-plan-picker-header__period {
  flex-basis: $payment-plan-picker-period-width;
}

.payment-plan-picker-header__price {
  flex-basis: $payment-plan-picker-price-width;
}

.payment-plan-picker-header__actions {
  flex-basis: $payment-plan-picker-actions-width;
}


/* Row
/* -------------------------------------------------------------------------- */
.payment-plan-picker-row {
  position: relative;
  background-color: white;
  width: 100%;
  height: 64px;
  display: flex;
  margin-bottom: 1px;
  font-size: 15px;
}

.payment-plan-picker-row[active] {
  background-color: lighten($color-success, 50%);
}

.payment-plan-picker-row__col {
  position: relative;
  z-index: 1;
  padding-left: $spacing-4;
  padding-right: $spacing-4;
  display: flex;
  align-items: center;
}

.payment-plan-picker-row__status {
  flex-basis: $payment-plan-picker-status-width;
  fill: $color-success;
}

.payment-plan-picker-row__terms {
  flex-basis: $payment-plan-picker-terms-width;
}

.payment-plan-picker-row__period {
  flex-basis: $payment-plan-picker-period-width;
}

.payment-plan-picker-row__price {
  flex-basis: $payment-plan-picker-price-width;
  font-weight: bold;
}

.payment-plan-picker-row__actions {
  flex-basis: $payment-plan-picker-actions-width;
  justify-content: flex-end;
}

.payment-plan-picker-row__info {
  display: flex;
  align-items: center;
}

.payment-plan-picker-row__info .icon {
  width: 20px;
  height: 20px;
  fill: $color-cyan;
}