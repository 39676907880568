/**
 * @component Unit Availability List
 */

$unit-availability-list-status-width: 15%;
$unit-availability-list-label-width: 15%;
$unit-availability-list-price-width: 20%;
$unit-availability-list-expiry-width: 20%;
$unit-availability-list-actions-width: 30%;

/* Unit Header
/* -------------------------------------------------------------------------- */
.unit-availability-header {
  display: flex;
  margin-bottom: $spacing-2;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: $color-text-l5;
  letter-spacing: 1px;
}

.unit-availability-header > div {
  padding-left: $spacing-4;
  padding-right: $spacing-4;
}

.unit-availability-header__status {
  flex-basis: $unit-availability-list-status-width;
}

.unit-availability-header__label {
  flex-basis: $unit-availability-list-label-width;
}

.unit-availability-header__price {
  flex-basis: $unit-availability-list-price-width;
}

.unit-availability-header__expiry {
  flex-basis: $unit-availability-list-expiry-width;
}


/* Unit Row
/* -------------------------------------------------------------------------- */
.unit-availability-row {
  position: relative;
  background-color: white;
  width: 100%;
  height: 64px;
  display: block;
  margin-bottom: 1px;
  display: flex;
  font-size: 15px;
}

.unit-availability-row:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: lighten($color-success, 50%);
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  transform-origin: left;
  transform: scaleX(0);
  opacity: 0;
}
.unit-availability-row[active]:before {
  transform: scaleX(1);
  opacity: 1;
}

.unit-availability-row__col {
  position: relative;
  z-index: 1;
  padding-left: $spacing-4;
  padding-right: $spacing-4;
  display: flex;
  align-items: center;
}

.unit-availability-row__status {
  flex-basis: $unit-availability-list-status-width;
  text-transform: capitalize;
}

.unit-availability-row__label {
  flex-basis: $unit-availability-list-label-width;
  font-weight: bold;
}

.unit-availability-row__price {
  flex-basis: $unit-availability-list-price-width;
}

.unit-availability-row__expiry {
  flex-basis: $unit-availability-list-expiry-width;
  color: $color-text-l3;

  & i {
    display: inline-block;
    margin-right: $spacing-2;
    color: $color-cyan;
    font-style: normal;
  }
}

.unit-availability-row__actions {
  margin-left: auto;
  flex-basis: $unit-availability-list-actions-width;
  justify-content: flex-end;
}

.unit-availability-row__actions .btn {
  height: 44px;
  width: 110px;
}

.unit-availability-row__actions .btn + .btn {
  margin-left: $spacing-1;
}

.unit-availability-row__indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: lightgrey;
  border-radius: 50%;
  margin-right: $spacing-3;

  &[status=available] { background-color: $color-success; }
  &[status=reserved] { background-color: $color-warn; }
  &[status=sold] { background-color: $color-danger; }
}

.unit-availability-empty {
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $color-text-l5;
}


/* Unit List Filter
/* -------------------------------------------------------------------------- */
.unit-filter {
  margin-top: -$spacing-5;
  margin-bottom: $spacing-5;
  text-align: right;
}

.unit-filter__item:after {
  // content: '|';
  // margin: 0 $spacing-3;
  // display: inline-block;
}
.unit-filter__item:last-of-type:after {
  display: none;
}

.unit-filter .btn {
  text-decoration: none;
  background-color: transparent;
  color: $color-text-l4;
  height: 42px;
  margin-left: -1px;

  &:last-of-type {
    margin-right: 0;
  }
}

.unit-filter .btn.active {
  // background-color: transparent !important;
  // color: $color-text !important;
}

.unit-filter .btn > span {
  font-size: 13px;
  opacity: 0.75;
  position: relative;
}