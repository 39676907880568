/**
 * @base Typography
 */

/* Font Family Definitions
/* -------------------------------------------------------------------------- */
$typeface-primary: 'Unique', serif;
$typeface-secondary: 'Life T', serif;
$typeface-body: 'Unique', serif;
$typeface-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Base Overrides
/* -------------------------------------------------------------------------- */
html, body {
  font-size: 16px;
  font-family: $typeface-body;
  line-height: 1.4;
}
