/**
 * @screen Error boundary component
 */

.error-boundary {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.error-boundary:after {
  content: ':(';
  font-family: monospace;
  position: absolute;
  bottom: -20%;
  right: 0;
  z-index: -1;
  font-size: 540px;
  letter-spacing: -75px;
  color: rgb(210, 210, 210);
}

.error-boundary__title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: $spacing-4;
}

.error-boundary__error {
  font-family: monospace;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: $spacing-5;
}

.error-boundary__error em {
  font-weight: normal;
  font-style: normal;
}

.error-boundary__info {
  align-self: center;
  flex-shrink: 0;
  width: 100%;
  max-width: 540px;
  max-height: 320px;
  overflow-y: auto;
  padding: $spacing-4;
  background-color: white;
  border: 1px solid $color-border;
  white-space: pre-wrap;
  text-align: left;
  font-family: monospace;
  font-size: 14px;
  color: $color-text-l2;
}