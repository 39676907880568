/**
 * @component Footer
 */

$dialog-footer-height: 84px;

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: $dialog-footer-height;
  z-index: 20;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  background-color: white;
}

.footer__error {
  color: $color-danger;
  font-weight: bold;
  margin-right: $spacing-4;
}

.footer[disable]:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(white, 0.6);
}