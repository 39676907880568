/**
 * @component Buttons
 */

.btn {
  border-radius: 0;
  font-family: $typeface-body;
  height: 48px;
}


/* Themes
/* -------------------------------------------------------------------------- */
.btn-secondary {
  background-color: lighten($color-grey, 5%);
  border: 0;

  &:disabled {
    background-color: lighten($color-grey, 15%);
  }
}

.btn-empty {
  background-color: transparent;
  padding: 0;
}

.btn-next {
  background-color: $color-success;
  border-color: $color-success;
  color: white;
}

.btn-cyan {
  background-color: $color-cyan;
  color: white;
}

.btn-outline-cyan {
  border-color: $color-cyan;
  color: $color-cyan;
  background-color: transparent;

  &:hover {
    background-color: $color-cyan;
    color: white;
  }
}

.btn-danger {
  color: #fff;
  background-color: $color-danger;
  border-color: $color-danger;
}


/* Size Modifiers
/* -------------------------------------------------------------------------- */
.btn-110 {
  width: 110px;
}

.btn-160 {
  width: 160px;
}

.btn[width=xs] {
  width: 80px;
}

.btn[width=sm] {
  width: 120px;
}

.btn[width=md] {
  width: 160px;
}

.btn[width=lg] {
  width: 200px;
}


/* Animation Classes
/* -------------------------------------------------------------------------- */
.btn.shake {
  animation: btn-shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}


/* Keyframe Definitions
/* -------------------------------------------------------------------------- */
@keyframes btn-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}