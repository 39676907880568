/**
 * @component Icons
 */
.icon {
  width: 32px;
  height: 32px;
}

.icon[small] {
  width: 16px;
  height: 16px;
}

.icon.icon-white {
  fill: white;
}