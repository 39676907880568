/**
 * @component Prospect List Component
 */

.prospect-list {
  @include m-list-unstyled;
  margin-bottom: 0;
}

.prospect-list li > button {
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: $spacing-4 0;
  border: 0;
  border-bottom: 1px solid $color-border-l2;
  background-color: transparent;
  text-align: left;
  color: $color-text-l1;

  &:focus {
    outline: none;
    color: $color-text;
  }
  &:focus:before {
    background-color: lighten($color-active, 40%);
  }
}

.prospect-list li:last-child > button {
  border-bottom: 0;
}

.prospect-list li > button:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -$spacing-4;
  bottom: -1px;
  right: -$spacing-4;
}

.prospect-list__content {
  position: relative;
  z-index: 2;
}

.prospect-list__chevron {
  position: relative;
  margin-left: auto;
  z-index: 2;
}

.prospect-list__chevron .icon {
  width: 12px;
  height: 12px;
  transform: rotate(-90deg);
  fill: $color-text-l2;
}


/* Prospect List Empty State
/* -------------------------------------------------------------------------- */
.prospect-list-empty {
  text-align: center;
  padding-top: $spacing-6;
  padding-bottom: $spacing-6;
}

.prospect-list-empty__title {
  display: block;
  font-size: 20px;
  color: $color-text-l4;
}

/* Prospect List Dialog
/* -------------------------------------------------------------------------- */
.prospect-list-dialog .modal-body {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 50vh;
  overflow-y: auto;
}